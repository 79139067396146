import { useSpring, animated } from '@react-spring/web'

import styles from './BottomCurve.css'

export function BottomCurve({ colorClassName, layoutClassName = undefined }) {
  const style = useScrollWithSpring()

  return <animated.span role="presentation" className={cx(styles.component, colorClassName, layoutClassName )} {...{ style }} />
}

function useScrollWithSpring() {
  const [velocity, setVelocity] = React.useState(0)
  const lastScrollYRef = React.useRef(0)
  const lastTimeRef = React.useRef(Date.now())
  const scrollTimeoutRef = React.useRef(null)
  const amplitude = 0.02
  const size = useSizeByViewportWidth()

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const currentTime = Date.now()
      const deltaY = currentScrollY - lastScrollYRef.current
      const deltaTime = (currentTime - lastTimeRef.current) / 1000

      const newVelocity = deltaY / deltaTime

      if (Math.abs(newVelocity) < 0.1) {
        setVelocity(0)
      } else {
        setVelocity(newVelocity)
      }

      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }

      scrollTimeoutRef.current = setTimeout(() => {
        setVelocity(0)
      }, 100)

      lastScrollYRef.current = currentScrollY
      lastTimeRef.current = currentTime
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }
    }
  }, [])

  const style = useSpring({
    clipPath: `ellipse(${size}% ${99 - Math.floor(velocity * amplitude)}% at 50% -35%)`,
    config: { tension: 200, friction: 50, mass: 10 },
  })

  return style
}

function useSizeByViewportWidth() {
  const [size, setSize] = React.useState(65)

  React.useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth
      const newSize = viewportWidth > 1024 ? 65 : 100
      setSize(newSize)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return size
}
